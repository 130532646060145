<template>
    <div class="pinyin">
        <div class="wordBox" v-for="(item,index) in list" :key="index">
            <ruby>{{item['hanzi']}}
                <rt>{{item['pinyin']}}</rt>
            </ruby>
        </div>
    </div>
</template>
<script>
    import { pinyin } from 'pinyin-pro';
    export default {
        name: "change-pinyin",
        props: {
            content: String,
        },
        mounted() {
            this.$nextTick(() => {
                this.initInfo()
            })
        },
        data() {
            return {
                list: []
            }
        },
        watch: {
            content: {
                deep: true,
                handler(val) {
                    this.content = val;
                    this.initInfo();
                }
            }
        },
        methods: {
            initInfo() {
                this.list = []
                for (let char of this.content) {
                    let pinyins = ''
                    // 判断是否为汉字
                    var reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
                    if (reg.test(char)) {
                        pinyins = pinyin(char)
                    }
                    this.list.push(
                        {
                            "hanzi": char,
                            "pinyin": pinyins
                        }
                    )
                }
            },
        }
    }
</script>
<style scoped>
    .pinyin {
        margin: 5px;
        display: flex;
        flex-flow: row wrap;
    }

    .wordBox {
        width: 1.5em;
        text-align: center;
    }
</style>
