<template>
	<div class="main">
		<div class="top-title">学习检测</div>
		<div class="center-box">
			<!--进度条-->
			<div class="progress-box">
				<el-progress
						:text-inside="true"
						:stroke-width="20"
						:percentage="percentage"
						color="#F7B500"
						textColor="#fffff"
				>
				</el-progress>
			</div>
			<!--答题内容-->
			<div class="question-box">
				<div class="question-title">
					<span>{{nowNum + 1}}.</span>
					<change-pinyin :content="list[nowNum].title"></change-pinyin>
				</div>
				<div class="question-item-box">
					<div v-for="(item,index) in list[nowNum].list" @click="selectItem(index)" :key="item.id" class="question-item">
						<div class="question-icon"><div v-if="list[nowNum].select === index"></div></div>
						<div class="info-box" :class="list[nowNum].select === index?'blue':''">
							<change-pinyin v-if="item.type === 1" :content="item.info"></change-pinyin>
							<img v-else alt="" class="question-img" :src="item.info">
						</div>
					</div>
				</div>
			</div>
			<!--底部按钮-->
			<div class="bottom-box">
				<div class="bottom-button" @click="getPrevious" :class="nowNum>0?'blue-bg':'grey-bg'">上一题</div>
				<div class="bottom-button" @click="toSubmit()" :class="nowNum === list.length-1?'red-bg':'grey-bg'">完成</div>
				<div class="bottom-button"  @click="getNext" :class="nowNum<list.length-1?'blue-bg':'grey-bg'">下一题</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAnswerInfo,
		submitAnswer
	} from '@/api/app'
	import changePinyin from './components/ChangePinyin.vue';
	export default {
		components: {
			changePinyin,
		},
		data() {
			return {
				id:'',
				percentage: 0, // 进度
				radio: '',
				list:[
					{
						title:'',
						list:''
					}
				], // 答题列表
				nowNum: 0, // 当前进行到第几题
			};
		},
		created() {
			const query = this.$route.query
			if (!query.id) {
				this.$message({ type: 'error', message: '参数错误' })
				return
			}
			this.id = query.id
			this.getList()
		},
		methods: {
			// 监听选择答案
			selectItem(type){
				this.list[this.nowNum].select = type
			},
			// 获取上一题
			getPrevious(){
				if(this.nowNum <= 0){
					return false
				}
				this.nowNum = this.nowNum - 1
				this.playAnimal()
				this.getProcess()
			},
			// 获取下一题
			getNext(){
				if(this.list[this.nowNum].select === ''){
					this.$message({message: '请先选择答案', type: 'error',duration: 3000});
					return false
				}
				if(this.nowNum >= this.list.length-1){
					return false
				}
				this.nowNum = this.nowNum + 1
				this.playAnimal()
				this.getProcess()
			},
			// 播放动画
			playAnimal(){
				let _this = this
				_this.animalClass = 'animated bounceOutLeft'
				setTimeout(function(){
					_this.animalClass = 'animated bounceInRight'
				},500);
			},
			// 计算进度
			getProcess(){
				if(this.list.length > 0){
					this.percentage = parseInt((this.nowNum/(this.list.length-1)).toFixed(2) * 100)
				}
			},
			// 获取答题数据
			getList(){
				let postData = {}
				postData.cId = this.id
				getAnswerInfo(postData).then(res => {
					this.list = res.data
				})
			},
			// 提交答题数据
			toSubmit(){
				if(this.list[this.nowNum].select === ''){
					this.$message({message: '请先选择答案', type: 'error',duration: 3000});
					return false
				}
				submitAnswer(this.list).then(res => {
					console.log(res)
					this.$message({message: '提交成功,等待老师批改。', type: 'success',duration: 5000});
					this.$router.push({ path:'/home', query:{} })
				})
			}
		}
	}
</script>

<style scoped>
	.main{
		width: 100vw;
		height: 100vh;
		position: relative;
		background-color:#2586FF;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.top-title{
		width: 100vw;
		text-align: center;
		color: #ffffff;
		font-size: 2vw;
		line-height: 5vw;
		font-weight: bold;
	}
	.center-box{
		width: 90vw;
		height: 85vh;
		border: 10px solid #e3757f;
		border-radius: 30px;
		background-color:rgba(255,255,255,0.6);
		position: relative;
	}
	.progress-box{
		width: 60vw;
		margin: 2vw auto;
		color: #F7B500;
	}
	/* 隐藏滚动条 */
	.question-box::-webkit-scrollbar {
		display: none; /* Chrome Safari */
	}
	.question-box{
		height: 65vh;
		overflow-y: scroll;
		padding: 0 5vw;

		overflow-x: hidden; /* 设置溢出滚动 */
		white-space: nowrap;
		/* 隐藏滚动条 */
		scrollbar-width: none; /* firefox */
		-ms-overflow-style: none; /* IE 10+ */
	}
	.question-title{
		color: #000000;
		font-size: 2vw;
		font-weight: bold;
		display: flex;
	}
	.question-title span{
		padding-top: 1vw;
	}
	.question-item-box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 2vw 0;
	}
	.question-item{
		width: 35vw;
		color: #000000;
		font-size: 1.8vw;
		padding-bottom: 1.9vw;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.blue{
		color: #2580F0;
	}
	.question-icon{
		width: 2vw;
		height: 2vw;
		border-radius: 50%;
		border: 2px solid #2580F0;
		margin-right: 1vw;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
	}
	.question-icon div{
		width: 1vw;
		height: 1vw;
		border-radius: 50%;
		background-color: #2580F0;
	}
	.info-box{

	}
	.question-img{
		width: 15vw;
	}
	.bottom-box{
		width: calc(100% - 4vw);
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2vw;
	}
	.bottom-button{
		width: 13vw;
		height: 5vw;
		line-height: 5vw;
		text-align: center;
		color: #ffffff;
		font-size: 2vw;
		font-weight: bold;
		border-radius: 10px;
		cursor: pointer;
	}
	.blue-bg{
		background-color: #2580F0;
	}
	.red-bg{
		background-color: #fc3664;
	}
	.grey-bg{
		background-color: #a0aaad;
	}
</style>
